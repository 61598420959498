@import "bootstrap.scss";

$success: hsl(157, 69%, 38%);
$alert-padding: 15px 20px;
$alert-border-radius: var(--border-radius);

$grey: #86868641;
$grey2: #707070;
$blue: #081d49;
$red: #c2000b;

body {
  font-family: "Helvetica Neue", Helvetica, Arial;
}

.bg-grey {
  background: $grey;
}

.is-sticky {
  z-index: 10 !important;
}

img {
  max-width: 100%;
  height: auto !important;
}

.actions {

  > [class^=" action"],
  > [class^="action"],
  .action-edit,
  .action-delete {
    display: inline-block;
    margin-bottom: 5px;
    @include button-size(5px, 15px, 15px, auto, 5px);
    @include button-variant($grey2, #000000, lighten($blue, 40%));
  }

  .action-edit {
    white-space: nowrap;
    color: #fff !important;
    @include button-variant($blue, #000000, lighten($blue, 40%));

    &:before {
      content: "View / ";
    }
  }

  .action-delete {
    color: #fff !important;
    @include button-variant($red, #000000, lighten($red, 40%));
  }
}

.actions,
.page-actions {

  .action-export-event-names {
    color: #fff;
    background-color: green;
  }
}


.is-sticky .content-header {
  margin: 0 !important;
  background: #fff;
  z-index: 10 !important;
  border-bottom: 1px solid $grey;

  &:after,
  &:before {
    content: " ";
    display: block;
    background: #fff;
    width: 30px;
    position: absolute;
    height: 100%;
    bottom: -1px;
    border-bottom: 1px solid $grey;
  }

  &:after {
    right: 100%;
  }

  &:before {
    left: 100%;
  }
}

.nav-tabs {
  .nav-item.enabled a {
    &:after {
      content: " ☑";
    }
  }

  .nav-item.error a {
    color: $red;

    &:after {
      display: inline-block;
      width: 15px;
      height: 10px;
      content: "";
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23cd3c63'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23cd3c63' stroke='none'/%3E%3C/svg%3E");
      background-position: right center;
      background-repeat: no-repeat;
    }
  }
}

.login-logo {
  width: 350px;
}

.login {
  color: $grey2;

  a {
    color: $blue;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .logged-in {
    padding-top: 30px;
    color: $red;
  }

  h1 {
    font-size: 40px;
    line-height: 49px;
    padding-top: 10px;
    margin-bottom: 40px;
    color: $grey2;
  }

  .field-wrapper {
  }

  label {
    text-transform: uppercase;
    font-weight: bold;
    padding-bottom: 5px;
    display: block;
  }

  input[type="email"],
  input[type="password"],
  input[type="text"] {
    padding: 5px 10px;
    line-height: 31px;
    width: 100%;
    border-radius: 0;
    border: 1px solid $grey2;
    margin-bottom: 20px;
  }

  .checkbox {
    display: block;
    margin-bottom: 20px;
  }

  input[type="submit"],
  button {
    background: $blue;
    color: #fff;
    border: none;
    border-radius: 10px;
    font-size: 20px;
    padding: 9px 27px;
    line-height: 20px;
    text-transform: uppercase;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .reset-password {
    margin-bottom: 20px;
  }
}

table.history {
  tr + tr > td {
    border-top: 1px solid #ddd;
  }

  td {
    padding: 3px 10px;
  }

  td + td {
    border-left: 1px solid #ddd;
  }
}

.search-duplicate-results .inner {
  right: 30px;
  top: 30px;
  padding: 5px 0px;
  z-index: 10000;
  color: #126244;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      padding-right: 5px;
    }

    li:after {
      content: ",";
    }

    li:last-child:after {
      content: "";
    }
  }
}

.form-group.remove-group {
  background: rgba(255, 0, 0, 0.2);

  .field-collection-delete-button {
    color: green;
  }

  .form-group.field-collection .accordion-button {
    color: red;
  }
}


