.container, .container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}

.row {
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.no-gutters > .col, .no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.col {
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.row-cols-1 > * {
  max-width: 100%;
  flex: 0 0 100%;
}

.row-cols-2 > * {
  max-width: 50%;
  flex: 0 0 50%;
}

.row-cols-3 > * {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.row-cols-4 > * {
  max-width: 25%;
  flex: 0 0 25%;
}

.row-cols-5 > * {
  max-width: 20%;
  flex: 0 0 20%;
}

.row-cols-6 > * {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-auto {
  width: auto;
  max-width: 100%;
  flex: none;
}

.col-1 {
  max-width: 8.33333%;
  flex: 0 0 8.33333%;
}

.col-2 {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-3 {
  max-width: 25%;
  flex: 0 0 25%;
}

.col-4 {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.col-5 {
  max-width: 41.6667%;
  flex: 0 0 41.6667%;
}

.col-6 {
  max-width: 50%;
  flex: 0 0 50%;
}

.col-7 {
  max-width: 58.3333%;
  flex: 0 0 58.3333%;
}

.col-8 {
  max-width: 66.6667%;
  flex: 0 0 66.6667%;
}

.col-9 {
  max-width: 75%;
  flex: 0 0 75%;
}

.col-10 {
  max-width: 83.3333%;
  flex: 0 0 83.3333%;
}

.col-11 {
  max-width: 91.6667%;
  flex: 0 0 91.6667%;
}

.col-12 {
  max-width: 100%;
  flex: 0 0 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

@media (min-width: 576px) {
  .col-sm {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-sm-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-sm-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-sm-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-sm-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-sm-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-sm-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-sm-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-sm-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-sm-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-sm-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-sm-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-sm-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-sm-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-sm-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-sm-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-sm-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-sm-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-md-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-md-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-md-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-md-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-md-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-md-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-md-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-md-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-md-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-md-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-md-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-md-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-md-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-md-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-md-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-md-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-md-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-lg-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-lg-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-lg-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-lg-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-lg-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-lg-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-lg-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-lg-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-lg-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-lg-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-lg-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-lg-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-lg-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-lg-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-lg-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-lg-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-xl-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-xl-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-xl-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-xl-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-xl-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-xl-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-xl-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-xl-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-xl-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-xl-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-xl-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-xl-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-xl-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-xl-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-xl-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-xl-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-xl-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: #00000080 !important;
}

.text-white-50 {
  color: #ffffff80 !important;
}

.text-hide {
  font: 0 / 0 a;
  color: #0000;
  text-shadow: none;
  background-color: #0000;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0, .my-0 {
  margin-top: 0 !important;
}

.mr-0, .mx-0 {
  margin-right: 0 !important;
}

.mb-0, .my-0 {
  margin-bottom: 0 !important;
}

.ml-0, .mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 3.75px !important;
}

.mt-1, .my-1 {
  margin-top: 3.75px !important;
}

.mr-1, .mx-1 {
  margin-right: 3.75px !important;
}

.mb-1, .my-1 {
  margin-bottom: 3.75px !important;
}

.ml-1, .mx-1 {
  margin-left: 3.75px !important;
}

.m-2 {
  margin: 7.5px !important;
}

.mt-2, .my-2 {
  margin-top: 7.5px !important;
}

.mr-2, .mx-2 {
  margin-right: 7.5px !important;
}

.mb-2, .my-2 {
  margin-bottom: 7.5px !important;
}

.ml-2, .mx-2 {
  margin-left: 7.5px !important;
}

.m-3 {
  margin: 15px !important;
}

.mt-3, .my-3 {
  margin-top: 15px !important;
}

.mr-3, .mx-3 {
  margin-right: 15px !important;
}

.mb-3, .my-3 {
  margin-bottom: 15px !important;
}

.ml-3, .mx-3 {
  margin-left: 15px !important;
}

.m-4 {
  margin: 22.5px !important;
}

.mt-4, .my-4 {
  margin-top: 22.5px !important;
}

.mr-4, .mx-4 {
  margin-right: 22.5px !important;
}

.mb-4, .my-4 {
  margin-bottom: 22.5px !important;
}

.ml-4, .mx-4 {
  margin-left: 22.5px !important;
}

.m-5 {
  margin: 45px !important;
}

.mt-5, .my-5 {
  margin-top: 45px !important;
}

.mr-5, .mx-5 {
  margin-right: 45px !important;
}

.mb-5, .my-5 {
  margin-bottom: 45px !important;
}

.ml-5, .mx-5 {
  margin-left: 45px !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0, .py-0 {
  padding-top: 0 !important;
}

.pr-0, .px-0 {
  padding-right: 0 !important;
}

.pb-0, .py-0 {
  padding-bottom: 0 !important;
}

.pl-0, .px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 3.75px !important;
}

.pt-1, .py-1 {
  padding-top: 3.75px !important;
}

.pr-1, .px-1 {
  padding-right: 3.75px !important;
}

.pb-1, .py-1 {
  padding-bottom: 3.75px !important;
}

.pl-1, .px-1 {
  padding-left: 3.75px !important;
}

.p-2 {
  padding: 7.5px !important;
}

.pt-2, .py-2 {
  padding-top: 7.5px !important;
}

.pr-2, .px-2 {
  padding-right: 7.5px !important;
}

.pb-2, .py-2 {
  padding-bottom: 7.5px !important;
}

.pl-2, .px-2 {
  padding-left: 7.5px !important;
}

.p-3 {
  padding: 15px !important;
}

.pt-3, .py-3 {
  padding-top: 15px !important;
}

.pr-3, .px-3 {
  padding-right: 15px !important;
}

.pb-3, .py-3 {
  padding-bottom: 15px !important;
}

.pl-3, .px-3 {
  padding-left: 15px !important;
}

.p-4 {
  padding: 22.5px !important;
}

.pt-4, .py-4 {
  padding-top: 22.5px !important;
}

.pr-4, .px-4 {
  padding-right: 22.5px !important;
}

.pb-4, .py-4 {
  padding-bottom: 22.5px !important;
}

.pl-4, .px-4 {
  padding-left: 22.5px !important;
}

.p-5 {
  padding: 45px !important;
}

.pt-5, .py-5 {
  padding-top: 45px !important;
}

.pr-5, .px-5 {
  padding-right: 45px !important;
}

.pb-5, .py-5 {
  padding-bottom: 45px !important;
}

.pl-5, .px-5 {
  padding-left: 45px !important;
}

.m-n1 {
  margin: -3.75px !important;
}

.mt-n1, .my-n1 {
  margin-top: -3.75px !important;
}

.mr-n1, .mx-n1 {
  margin-right: -3.75px !important;
}

.mb-n1, .my-n1 {
  margin-bottom: -3.75px !important;
}

.ml-n1, .mx-n1 {
  margin-left: -3.75px !important;
}

.m-n2 {
  margin: -7.5px !important;
}

.mt-n2, .my-n2 {
  margin-top: -7.5px !important;
}

.mr-n2, .mx-n2 {
  margin-right: -7.5px !important;
}

.mb-n2, .my-n2 {
  margin-bottom: -7.5px !important;
}

.ml-n2, .mx-n2 {
  margin-left: -7.5px !important;
}

.m-n3 {
  margin: -15px !important;
}

.mt-n3, .my-n3 {
  margin-top: -15px !important;
}

.mr-n3, .mx-n3 {
  margin-right: -15px !important;
}

.mb-n3, .my-n3 {
  margin-bottom: -15px !important;
}

.ml-n3, .mx-n3 {
  margin-left: -15px !important;
}

.m-n4 {
  margin: -22.5px !important;
}

.mt-n4, .my-n4 {
  margin-top: -22.5px !important;
}

.mr-n4, .mx-n4 {
  margin-right: -22.5px !important;
}

.mb-n4, .my-n4 {
  margin-bottom: -22.5px !important;
}

.ml-n4, .mx-n4 {
  margin-left: -22.5px !important;
}

.m-n5 {
  margin: -45px !important;
}

.mt-n5, .my-n5 {
  margin-top: -45px !important;
}

.mr-n5, .mx-n5 {
  margin-right: -45px !important;
}

.mb-n5, .my-n5 {
  margin-bottom: -45px !important;
}

.ml-n5, .mx-n5 {
  margin-left: -45px !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto, .my-auto {
  margin-top: auto !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}

.mb-auto, .my-auto {
  margin-bottom: auto !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0, .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0, .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 3.75px !important;
  }

  .mt-sm-1, .my-sm-1 {
    margin-top: 3.75px !important;
  }

  .mr-sm-1, .mx-sm-1 {
    margin-right: 3.75px !important;
  }

  .mb-sm-1, .my-sm-1 {
    margin-bottom: 3.75px !important;
  }

  .ml-sm-1, .mx-sm-1 {
    margin-left: 3.75px !important;
  }

  .m-sm-2 {
    margin: 7.5px !important;
  }

  .mt-sm-2, .my-sm-2 {
    margin-top: 7.5px !important;
  }

  .mr-sm-2, .mx-sm-2 {
    margin-right: 7.5px !important;
  }

  .mb-sm-2, .my-sm-2 {
    margin-bottom: 7.5px !important;
  }

  .ml-sm-2, .mx-sm-2 {
    margin-left: 7.5px !important;
  }

  .m-sm-3 {
    margin: 15px !important;
  }

  .mt-sm-3, .my-sm-3 {
    margin-top: 15px !important;
  }

  .mr-sm-3, .mx-sm-3 {
    margin-right: 15px !important;
  }

  .mb-sm-3, .my-sm-3 {
    margin-bottom: 15px !important;
  }

  .ml-sm-3, .mx-sm-3 {
    margin-left: 15px !important;
  }

  .m-sm-4 {
    margin: 22.5px !important;
  }

  .mt-sm-4, .my-sm-4 {
    margin-top: 22.5px !important;
  }

  .mr-sm-4, .mx-sm-4 {
    margin-right: 22.5px !important;
  }

  .mb-sm-4, .my-sm-4 {
    margin-bottom: 22.5px !important;
  }

  .ml-sm-4, .mx-sm-4 {
    margin-left: 22.5px !important;
  }

  .m-sm-5 {
    margin: 45px !important;
  }

  .mt-sm-5, .my-sm-5 {
    margin-top: 45px !important;
  }

  .mr-sm-5, .mx-sm-5 {
    margin-right: 45px !important;
  }

  .mb-sm-5, .my-sm-5 {
    margin-bottom: 45px !important;
  }

  .ml-sm-5, .mx-sm-5 {
    margin-left: 45px !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0, .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0, .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0, .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0, .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 3.75px !important;
  }

  .pt-sm-1, .py-sm-1 {
    padding-top: 3.75px !important;
  }

  .pr-sm-1, .px-sm-1 {
    padding-right: 3.75px !important;
  }

  .pb-sm-1, .py-sm-1 {
    padding-bottom: 3.75px !important;
  }

  .pl-sm-1, .px-sm-1 {
    padding-left: 3.75px !important;
  }

  .p-sm-2 {
    padding: 7.5px !important;
  }

  .pt-sm-2, .py-sm-2 {
    padding-top: 7.5px !important;
  }

  .pr-sm-2, .px-sm-2 {
    padding-right: 7.5px !important;
  }

  .pb-sm-2, .py-sm-2 {
    padding-bottom: 7.5px !important;
  }

  .pl-sm-2, .px-sm-2 {
    padding-left: 7.5px !important;
  }

  .p-sm-3 {
    padding: 15px !important;
  }

  .pt-sm-3, .py-sm-3 {
    padding-top: 15px !important;
  }

  .pr-sm-3, .px-sm-3 {
    padding-right: 15px !important;
  }

  .pb-sm-3, .py-sm-3 {
    padding-bottom: 15px !important;
  }

  .pl-sm-3, .px-sm-3 {
    padding-left: 15px !important;
  }

  .p-sm-4 {
    padding: 22.5px !important;
  }

  .pt-sm-4, .py-sm-4 {
    padding-top: 22.5px !important;
  }

  .pr-sm-4, .px-sm-4 {
    padding-right: 22.5px !important;
  }

  .pb-sm-4, .py-sm-4 {
    padding-bottom: 22.5px !important;
  }

  .pl-sm-4, .px-sm-4 {
    padding-left: 22.5px !important;
  }

  .p-sm-5 {
    padding: 45px !important;
  }

  .pt-sm-5, .py-sm-5 {
    padding-top: 45px !important;
  }

  .pr-sm-5, .px-sm-5 {
    padding-right: 45px !important;
  }

  .pb-sm-5, .py-sm-5 {
    padding-bottom: 45px !important;
  }

  .pl-sm-5, .px-sm-5 {
    padding-left: 45px !important;
  }

  .m-sm-n1 {
    margin: -3.75px !important;
  }

  .mt-sm-n1, .my-sm-n1 {
    margin-top: -3.75px !important;
  }

  .mr-sm-n1, .mx-sm-n1 {
    margin-right: -3.75px !important;
  }

  .mb-sm-n1, .my-sm-n1 {
    margin-bottom: -3.75px !important;
  }

  .ml-sm-n1, .mx-sm-n1 {
    margin-left: -3.75px !important;
  }

  .m-sm-n2 {
    margin: -7.5px !important;
  }

  .mt-sm-n2, .my-sm-n2 {
    margin-top: -7.5px !important;
  }

  .mr-sm-n2, .mx-sm-n2 {
    margin-right: -7.5px !important;
  }

  .mb-sm-n2, .my-sm-n2 {
    margin-bottom: -7.5px !important;
  }

  .ml-sm-n2, .mx-sm-n2 {
    margin-left: -7.5px !important;
  }

  .m-sm-n3 {
    margin: -15px !important;
  }

  .mt-sm-n3, .my-sm-n3 {
    margin-top: -15px !important;
  }

  .mr-sm-n3, .mx-sm-n3 {
    margin-right: -15px !important;
  }

  .mb-sm-n3, .my-sm-n3 {
    margin-bottom: -15px !important;
  }

  .ml-sm-n3, .mx-sm-n3 {
    margin-left: -15px !important;
  }

  .m-sm-n4 {
    margin: -22.5px !important;
  }

  .mt-sm-n4, .my-sm-n4 {
    margin-top: -22.5px !important;
  }

  .mr-sm-n4, .mx-sm-n4 {
    margin-right: -22.5px !important;
  }

  .mb-sm-n4, .my-sm-n4 {
    margin-bottom: -22.5px !important;
  }

  .ml-sm-n4, .mx-sm-n4 {
    margin-left: -22.5px !important;
  }

  .m-sm-n5 {
    margin: -45px !important;
  }

  .mt-sm-n5, .my-sm-n5 {
    margin-top: -45px !important;
  }

  .mr-sm-n5, .mx-sm-n5 {
    margin-right: -45px !important;
  }

  .mb-sm-n5, .my-sm-n5 {
    margin-bottom: -45px !important;
  }

  .ml-sm-n5, .mx-sm-n5 {
    margin-left: -45px !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto, .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto, .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto, .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto, .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0, .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0, .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0, .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0, .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 3.75px !important;
  }

  .mt-md-1, .my-md-1 {
    margin-top: 3.75px !important;
  }

  .mr-md-1, .mx-md-1 {
    margin-right: 3.75px !important;
  }

  .mb-md-1, .my-md-1 {
    margin-bottom: 3.75px !important;
  }

  .ml-md-1, .mx-md-1 {
    margin-left: 3.75px !important;
  }

  .m-md-2 {
    margin: 7.5px !important;
  }

  .mt-md-2, .my-md-2 {
    margin-top: 7.5px !important;
  }

  .mr-md-2, .mx-md-2 {
    margin-right: 7.5px !important;
  }

  .mb-md-2, .my-md-2 {
    margin-bottom: 7.5px !important;
  }

  .ml-md-2, .mx-md-2 {
    margin-left: 7.5px !important;
  }

  .m-md-3 {
    margin: 15px !important;
  }

  .mt-md-3, .my-md-3 {
    margin-top: 15px !important;
  }

  .mr-md-3, .mx-md-3 {
    margin-right: 15px !important;
  }

  .mb-md-3, .my-md-3 {
    margin-bottom: 15px !important;
  }

  .ml-md-3, .mx-md-3 {
    margin-left: 15px !important;
  }

  .m-md-4 {
    margin: 22.5px !important;
  }

  .mt-md-4, .my-md-4 {
    margin-top: 22.5px !important;
  }

  .mr-md-4, .mx-md-4 {
    margin-right: 22.5px !important;
  }

  .mb-md-4, .my-md-4 {
    margin-bottom: 22.5px !important;
  }

  .ml-md-4, .mx-md-4 {
    margin-left: 22.5px !important;
  }

  .m-md-5 {
    margin: 45px !important;
  }

  .mt-md-5, .my-md-5 {
    margin-top: 45px !important;
  }

  .mr-md-5, .mx-md-5 {
    margin-right: 45px !important;
  }

  .mb-md-5, .my-md-5 {
    margin-bottom: 45px !important;
  }

  .ml-md-5, .mx-md-5 {
    margin-left: 45px !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0, .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0, .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0, .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0, .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 3.75px !important;
  }

  .pt-md-1, .py-md-1 {
    padding-top: 3.75px !important;
  }

  .pr-md-1, .px-md-1 {
    padding-right: 3.75px !important;
  }

  .pb-md-1, .py-md-1 {
    padding-bottom: 3.75px !important;
  }

  .pl-md-1, .px-md-1 {
    padding-left: 3.75px !important;
  }

  .p-md-2 {
    padding: 7.5px !important;
  }

  .pt-md-2, .py-md-2 {
    padding-top: 7.5px !important;
  }

  .pr-md-2, .px-md-2 {
    padding-right: 7.5px !important;
  }

  .pb-md-2, .py-md-2 {
    padding-bottom: 7.5px !important;
  }

  .pl-md-2, .px-md-2 {
    padding-left: 7.5px !important;
  }

  .p-md-3 {
    padding: 15px !important;
  }

  .pt-md-3, .py-md-3 {
    padding-top: 15px !important;
  }

  .pr-md-3, .px-md-3 {
    padding-right: 15px !important;
  }

  .pb-md-3, .py-md-3 {
    padding-bottom: 15px !important;
  }

  .pl-md-3, .px-md-3 {
    padding-left: 15px !important;
  }

  .p-md-4 {
    padding: 22.5px !important;
  }

  .pt-md-4, .py-md-4 {
    padding-top: 22.5px !important;
  }

  .pr-md-4, .px-md-4 {
    padding-right: 22.5px !important;
  }

  .pb-md-4, .py-md-4 {
    padding-bottom: 22.5px !important;
  }

  .pl-md-4, .px-md-4 {
    padding-left: 22.5px !important;
  }

  .p-md-5 {
    padding: 45px !important;
  }

  .pt-md-5, .py-md-5 {
    padding-top: 45px !important;
  }

  .pr-md-5, .px-md-5 {
    padding-right: 45px !important;
  }

  .pb-md-5, .py-md-5 {
    padding-bottom: 45px !important;
  }

  .pl-md-5, .px-md-5 {
    padding-left: 45px !important;
  }

  .m-md-n1 {
    margin: -3.75px !important;
  }

  .mt-md-n1, .my-md-n1 {
    margin-top: -3.75px !important;
  }

  .mr-md-n1, .mx-md-n1 {
    margin-right: -3.75px !important;
  }

  .mb-md-n1, .my-md-n1 {
    margin-bottom: -3.75px !important;
  }

  .ml-md-n1, .mx-md-n1 {
    margin-left: -3.75px !important;
  }

  .m-md-n2 {
    margin: -7.5px !important;
  }

  .mt-md-n2, .my-md-n2 {
    margin-top: -7.5px !important;
  }

  .mr-md-n2, .mx-md-n2 {
    margin-right: -7.5px !important;
  }

  .mb-md-n2, .my-md-n2 {
    margin-bottom: -7.5px !important;
  }

  .ml-md-n2, .mx-md-n2 {
    margin-left: -7.5px !important;
  }

  .m-md-n3 {
    margin: -15px !important;
  }

  .mt-md-n3, .my-md-n3 {
    margin-top: -15px !important;
  }

  .mr-md-n3, .mx-md-n3 {
    margin-right: -15px !important;
  }

  .mb-md-n3, .my-md-n3 {
    margin-bottom: -15px !important;
  }

  .ml-md-n3, .mx-md-n3 {
    margin-left: -15px !important;
  }

  .m-md-n4 {
    margin: -22.5px !important;
  }

  .mt-md-n4, .my-md-n4 {
    margin-top: -22.5px !important;
  }

  .mr-md-n4, .mx-md-n4 {
    margin-right: -22.5px !important;
  }

  .mb-md-n4, .my-md-n4 {
    margin-bottom: -22.5px !important;
  }

  .ml-md-n4, .mx-md-n4 {
    margin-left: -22.5px !important;
  }

  .m-md-n5 {
    margin: -45px !important;
  }

  .mt-md-n5, .my-md-n5 {
    margin-top: -45px !important;
  }

  .mr-md-n5, .mx-md-n5 {
    margin-right: -45px !important;
  }

  .mb-md-n5, .my-md-n5 {
    margin-bottom: -45px !important;
  }

  .ml-md-n5, .mx-md-n5 {
    margin-left: -45px !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto, .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto, .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto, .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto, .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0, .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0, .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0, .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 3.75px !important;
  }

  .mt-lg-1, .my-lg-1 {
    margin-top: 3.75px !important;
  }

  .mr-lg-1, .mx-lg-1 {
    margin-right: 3.75px !important;
  }

  .mb-lg-1, .my-lg-1 {
    margin-bottom: 3.75px !important;
  }

  .ml-lg-1, .mx-lg-1 {
    margin-left: 3.75px !important;
  }

  .m-lg-2 {
    margin: 7.5px !important;
  }

  .mt-lg-2, .my-lg-2 {
    margin-top: 7.5px !important;
  }

  .mr-lg-2, .mx-lg-2 {
    margin-right: 7.5px !important;
  }

  .mb-lg-2, .my-lg-2 {
    margin-bottom: 7.5px !important;
  }

  .ml-lg-2, .mx-lg-2 {
    margin-left: 7.5px !important;
  }

  .m-lg-3 {
    margin: 15px !important;
  }

  .mt-lg-3, .my-lg-3 {
    margin-top: 15px !important;
  }

  .mr-lg-3, .mx-lg-3 {
    margin-right: 15px !important;
  }

  .mb-lg-3, .my-lg-3 {
    margin-bottom: 15px !important;
  }

  .ml-lg-3, .mx-lg-3 {
    margin-left: 15px !important;
  }

  .m-lg-4 {
    margin: 22.5px !important;
  }

  .mt-lg-4, .my-lg-4 {
    margin-top: 22.5px !important;
  }

  .mr-lg-4, .mx-lg-4 {
    margin-right: 22.5px !important;
  }

  .mb-lg-4, .my-lg-4 {
    margin-bottom: 22.5px !important;
  }

  .ml-lg-4, .mx-lg-4 {
    margin-left: 22.5px !important;
  }

  .m-lg-5 {
    margin: 45px !important;
  }

  .mt-lg-5, .my-lg-5 {
    margin-top: 45px !important;
  }

  .mr-lg-5, .mx-lg-5 {
    margin-right: 45px !important;
  }

  .mb-lg-5, .my-lg-5 {
    margin-bottom: 45px !important;
  }

  .ml-lg-5, .mx-lg-5 {
    margin-left: 45px !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0, .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0, .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0, .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0, .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 3.75px !important;
  }

  .pt-lg-1, .py-lg-1 {
    padding-top: 3.75px !important;
  }

  .pr-lg-1, .px-lg-1 {
    padding-right: 3.75px !important;
  }

  .pb-lg-1, .py-lg-1 {
    padding-bottom: 3.75px !important;
  }

  .pl-lg-1, .px-lg-1 {
    padding-left: 3.75px !important;
  }

  .p-lg-2 {
    padding: 7.5px !important;
  }

  .pt-lg-2, .py-lg-2 {
    padding-top: 7.5px !important;
  }

  .pr-lg-2, .px-lg-2 {
    padding-right: 7.5px !important;
  }

  .pb-lg-2, .py-lg-2 {
    padding-bottom: 7.5px !important;
  }

  .pl-lg-2, .px-lg-2 {
    padding-left: 7.5px !important;
  }

  .p-lg-3 {
    padding: 15px !important;
  }

  .pt-lg-3, .py-lg-3 {
    padding-top: 15px !important;
  }

  .pr-lg-3, .px-lg-3 {
    padding-right: 15px !important;
  }

  .pb-lg-3, .py-lg-3 {
    padding-bottom: 15px !important;
  }

  .pl-lg-3, .px-lg-3 {
    padding-left: 15px !important;
  }

  .p-lg-4 {
    padding: 22.5px !important;
  }

  .pt-lg-4, .py-lg-4 {
    padding-top: 22.5px !important;
  }

  .pr-lg-4, .px-lg-4 {
    padding-right: 22.5px !important;
  }

  .pb-lg-4, .py-lg-4 {
    padding-bottom: 22.5px !important;
  }

  .pl-lg-4, .px-lg-4 {
    padding-left: 22.5px !important;
  }

  .p-lg-5 {
    padding: 45px !important;
  }

  .pt-lg-5, .py-lg-5 {
    padding-top: 45px !important;
  }

  .pr-lg-5, .px-lg-5 {
    padding-right: 45px !important;
  }

  .pb-lg-5, .py-lg-5 {
    padding-bottom: 45px !important;
  }

  .pl-lg-5, .px-lg-5 {
    padding-left: 45px !important;
  }

  .m-lg-n1 {
    margin: -3.75px !important;
  }

  .mt-lg-n1, .my-lg-n1 {
    margin-top: -3.75px !important;
  }

  .mr-lg-n1, .mx-lg-n1 {
    margin-right: -3.75px !important;
  }

  .mb-lg-n1, .my-lg-n1 {
    margin-bottom: -3.75px !important;
  }

  .ml-lg-n1, .mx-lg-n1 {
    margin-left: -3.75px !important;
  }

  .m-lg-n2 {
    margin: -7.5px !important;
  }

  .mt-lg-n2, .my-lg-n2 {
    margin-top: -7.5px !important;
  }

  .mr-lg-n2, .mx-lg-n2 {
    margin-right: -7.5px !important;
  }

  .mb-lg-n2, .my-lg-n2 {
    margin-bottom: -7.5px !important;
  }

  .ml-lg-n2, .mx-lg-n2 {
    margin-left: -7.5px !important;
  }

  .m-lg-n3 {
    margin: -15px !important;
  }

  .mt-lg-n3, .my-lg-n3 {
    margin-top: -15px !important;
  }

  .mr-lg-n3, .mx-lg-n3 {
    margin-right: -15px !important;
  }

  .mb-lg-n3, .my-lg-n3 {
    margin-bottom: -15px !important;
  }

  .ml-lg-n3, .mx-lg-n3 {
    margin-left: -15px !important;
  }

  .m-lg-n4 {
    margin: -22.5px !important;
  }

  .mt-lg-n4, .my-lg-n4 {
    margin-top: -22.5px !important;
  }

  .mr-lg-n4, .mx-lg-n4 {
    margin-right: -22.5px !important;
  }

  .mb-lg-n4, .my-lg-n4 {
    margin-bottom: -22.5px !important;
  }

  .ml-lg-n4, .mx-lg-n4 {
    margin-left: -22.5px !important;
  }

  .m-lg-n5 {
    margin: -45px !important;
  }

  .mt-lg-n5, .my-lg-n5 {
    margin-top: -45px !important;
  }

  .mr-lg-n5, .mx-lg-n5 {
    margin-right: -45px !important;
  }

  .mb-lg-n5, .my-lg-n5 {
    margin-bottom: -45px !important;
  }

  .ml-lg-n5, .mx-lg-n5 {
    margin-left: -45px !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto, .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto, .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto, .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto, .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0, .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0, .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0, .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0, .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 3.75px !important;
  }

  .mt-xl-1, .my-xl-1 {
    margin-top: 3.75px !important;
  }

  .mr-xl-1, .mx-xl-1 {
    margin-right: 3.75px !important;
  }

  .mb-xl-1, .my-xl-1 {
    margin-bottom: 3.75px !important;
  }

  .ml-xl-1, .mx-xl-1 {
    margin-left: 3.75px !important;
  }

  .m-xl-2 {
    margin: 7.5px !important;
  }

  .mt-xl-2, .my-xl-2 {
    margin-top: 7.5px !important;
  }

  .mr-xl-2, .mx-xl-2 {
    margin-right: 7.5px !important;
  }

  .mb-xl-2, .my-xl-2 {
    margin-bottom: 7.5px !important;
  }

  .ml-xl-2, .mx-xl-2 {
    margin-left: 7.5px !important;
  }

  .m-xl-3 {
    margin: 15px !important;
  }

  .mt-xl-3, .my-xl-3 {
    margin-top: 15px !important;
  }

  .mr-xl-3, .mx-xl-3 {
    margin-right: 15px !important;
  }

  .mb-xl-3, .my-xl-3 {
    margin-bottom: 15px !important;
  }

  .ml-xl-3, .mx-xl-3 {
    margin-left: 15px !important;
  }

  .m-xl-4 {
    margin: 22.5px !important;
  }

  .mt-xl-4, .my-xl-4 {
    margin-top: 22.5px !important;
  }

  .mr-xl-4, .mx-xl-4 {
    margin-right: 22.5px !important;
  }

  .mb-xl-4, .my-xl-4 {
    margin-bottom: 22.5px !important;
  }

  .ml-xl-4, .mx-xl-4 {
    margin-left: 22.5px !important;
  }

  .m-xl-5 {
    margin: 45px !important;
  }

  .mt-xl-5, .my-xl-5 {
    margin-top: 45px !important;
  }

  .mr-xl-5, .mx-xl-5 {
    margin-right: 45px !important;
  }

  .mb-xl-5, .my-xl-5 {
    margin-bottom: 45px !important;
  }

  .ml-xl-5, .mx-xl-5 {
    margin-left: 45px !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0, .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0, .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0, .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0, .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 3.75px !important;
  }

  .pt-xl-1, .py-xl-1 {
    padding-top: 3.75px !important;
  }

  .pr-xl-1, .px-xl-1 {
    padding-right: 3.75px !important;
  }

  .pb-xl-1, .py-xl-1 {
    padding-bottom: 3.75px !important;
  }

  .pl-xl-1, .px-xl-1 {
    padding-left: 3.75px !important;
  }

  .p-xl-2 {
    padding: 7.5px !important;
  }

  .pt-xl-2, .py-xl-2 {
    padding-top: 7.5px !important;
  }

  .pr-xl-2, .px-xl-2 {
    padding-right: 7.5px !important;
  }

  .pb-xl-2, .py-xl-2 {
    padding-bottom: 7.5px !important;
  }

  .pl-xl-2, .px-xl-2 {
    padding-left: 7.5px !important;
  }

  .p-xl-3 {
    padding: 15px !important;
  }

  .pt-xl-3, .py-xl-3 {
    padding-top: 15px !important;
  }

  .pr-xl-3, .px-xl-3 {
    padding-right: 15px !important;
  }

  .pb-xl-3, .py-xl-3 {
    padding-bottom: 15px !important;
  }

  .pl-xl-3, .px-xl-3 {
    padding-left: 15px !important;
  }

  .p-xl-4 {
    padding: 22.5px !important;
  }

  .pt-xl-4, .py-xl-4 {
    padding-top: 22.5px !important;
  }

  .pr-xl-4, .px-xl-4 {
    padding-right: 22.5px !important;
  }

  .pb-xl-4, .py-xl-4 {
    padding-bottom: 22.5px !important;
  }

  .pl-xl-4, .px-xl-4 {
    padding-left: 22.5px !important;
  }

  .p-xl-5 {
    padding: 45px !important;
  }

  .pt-xl-5, .py-xl-5 {
    padding-top: 45px !important;
  }

  .pr-xl-5, .px-xl-5 {
    padding-right: 45px !important;
  }

  .pb-xl-5, .py-xl-5 {
    padding-bottom: 45px !important;
  }

  .pl-xl-5, .px-xl-5 {
    padding-left: 45px !important;
  }

  .m-xl-n1 {
    margin: -3.75px !important;
  }

  .mt-xl-n1, .my-xl-n1 {
    margin-top: -3.75px !important;
  }

  .mr-xl-n1, .mx-xl-n1 {
    margin-right: -3.75px !important;
  }

  .mb-xl-n1, .my-xl-n1 {
    margin-bottom: -3.75px !important;
  }

  .ml-xl-n1, .mx-xl-n1 {
    margin-left: -3.75px !important;
  }

  .m-xl-n2 {
    margin: -7.5px !important;
  }

  .mt-xl-n2, .my-xl-n2 {
    margin-top: -7.5px !important;
  }

  .mr-xl-n2, .mx-xl-n2 {
    margin-right: -7.5px !important;
  }

  .mb-xl-n2, .my-xl-n2 {
    margin-bottom: -7.5px !important;
  }

  .ml-xl-n2, .mx-xl-n2 {
    margin-left: -7.5px !important;
  }

  .m-xl-n3 {
    margin: -15px !important;
  }

  .mt-xl-n3, .my-xl-n3 {
    margin-top: -15px !important;
  }

  .mr-xl-n3, .mx-xl-n3 {
    margin-right: -15px !important;
  }

  .mb-xl-n3, .my-xl-n3 {
    margin-bottom: -15px !important;
  }

  .ml-xl-n3, .mx-xl-n3 {
    margin-left: -15px !important;
  }

  .m-xl-n4 {
    margin: -22.5px !important;
  }

  .mt-xl-n4, .my-xl-n4 {
    margin-top: -22.5px !important;
  }

  .mr-xl-n4, .mx-xl-n4 {
    margin-right: -22.5px !important;
  }

  .mb-xl-n4, .my-xl-n4 {
    margin-bottom: -22.5px !important;
  }

  .ml-xl-n4, .mx-xl-n4 {
    margin-left: -22.5px !important;
  }

  .m-xl-n5 {
    margin: -45px !important;
  }

  .mt-xl-n5, .my-xl-n5 {
    margin-top: -45px !important;
  }

  .mr-xl-n5, .mx-xl-n5 {
    margin-right: -45px !important;
  }

  .mb-xl-n5, .my-xl-n5 {
    margin-bottom: -45px !important;
  }

  .ml-xl-n5, .mx-xl-n5 {
    margin-left: -45px !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto, .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto, .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto, .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto, .mx-xl-auto {
    margin-left: auto !important;
  }
}

.alert {
  border: 1px solid #0000;
  border-radius: .25rem;
  margin-bottom: 1rem;
  padding: .75rem 1.25rem;
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  z-index: 2;
  color: inherit;
  padding: .75rem 1.25rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

.container, .container-fluid, .container-xl, .container-lg, .container-md, .container-sm {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}

.row {
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.no-gutters > .col, .no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.col {
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.row-cols-1 > * {
  max-width: 100%;
  flex: 0 0 100%;
}

.row-cols-2 > * {
  max-width: 50%;
  flex: 0 0 50%;
}

.row-cols-3 > * {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.row-cols-4 > * {
  max-width: 25%;
  flex: 0 0 25%;
}

.row-cols-5 > * {
  max-width: 20%;
  flex: 0 0 20%;
}

.row-cols-6 > * {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-auto {
  width: auto;
  max-width: 100%;
  flex: none;
}

.col-1 {
  max-width: 8.33333%;
  flex: 0 0 8.33333%;
}

.col-2 {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-3 {
  max-width: 25%;
  flex: 0 0 25%;
}

.col-4 {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.col-5 {
  max-width: 41.6667%;
  flex: 0 0 41.6667%;
}

.col-6 {
  max-width: 50%;
  flex: 0 0 50%;
}

.col-7 {
  max-width: 58.3333%;
  flex: 0 0 58.3333%;
}

.col-8 {
  max-width: 66.6667%;
  flex: 0 0 66.6667%;
}

.col-9 {
  max-width: 75%;
  flex: 0 0 75%;
}

.col-10 {
  max-width: 83.3333%;
  flex: 0 0 83.3333%;
}

.col-11 {
  max-width: 91.6667%;
  flex: 0 0 91.6667%;
}

.col-12 {
  max-width: 100%;
  flex: 0 0 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

@media (min-width: 576px) {
  .col-sm {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-sm-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-sm-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-sm-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-sm-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-sm-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-sm-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-sm-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-sm-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-sm-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-sm-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-sm-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-sm-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-sm-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-sm-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-sm-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-sm-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-sm-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-md-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-md-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-md-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-md-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-md-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-md-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-md-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-md-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-md-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-md-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-md-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-md-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-md-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-md-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-md-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-md-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-md-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-lg-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-lg-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-lg-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-lg-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-lg-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-lg-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-lg-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-lg-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-lg-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-lg-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-lg-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-lg-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-lg-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-lg-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-lg-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-lg-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-xl-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-xl-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-xl-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-xl-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-xl-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-xl-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-xl-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-xl-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-xl-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-xl-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-xl-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-xl-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-xl-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-xl-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-xl-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-xl-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-xl-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: #00000080 !important;
}

.text-white-50 {
  color: #ffffff80 !important;
}

.text-hide {
  font: 0 / 0 a;
  color: #0000;
  text-shadow: none;
  background-color: #0000;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0, .my-0 {
  margin-top: 0 !important;
}

.mr-0, .mx-0 {
  margin-right: 0 !important;
}

.mb-0, .my-0 {
  margin-bottom: 0 !important;
}

.ml-0, .mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 3.75px !important;
}

.mt-1, .my-1 {
  margin-top: 3.75px !important;
}

.mr-1, .mx-1 {
  margin-right: 3.75px !important;
}

.mb-1, .my-1 {
  margin-bottom: 3.75px !important;
}

.ml-1, .mx-1 {
  margin-left: 3.75px !important;
}

.m-2 {
  margin: 7.5px !important;
}

.mt-2, .my-2 {
  margin-top: 7.5px !important;
}

.mr-2, .mx-2 {
  margin-right: 7.5px !important;
}

.mb-2, .my-2 {
  margin-bottom: 7.5px !important;
}

.ml-2, .mx-2 {
  margin-left: 7.5px !important;
}

.m-3 {
  margin: 15px !important;
}

.mt-3, .my-3 {
  margin-top: 15px !important;
}

.mr-3, .mx-3 {
  margin-right: 15px !important;
}

.mb-3, .my-3 {
  margin-bottom: 15px !important;
}

.ml-3, .mx-3 {
  margin-left: 15px !important;
}

.m-4 {
  margin: 22.5px !important;
}

.mt-4, .my-4 {
  margin-top: 22.5px !important;
}

.mr-4, .mx-4 {
  margin-right: 22.5px !important;
}

.mb-4, .my-4 {
  margin-bottom: 22.5px !important;
}

.ml-4, .mx-4 {
  margin-left: 22.5px !important;
}

.m-5 {
  margin: 45px !important;
}

.mt-5, .my-5 {
  margin-top: 45px !important;
}

.mr-5, .mx-5 {
  margin-right: 45px !important;
}

.mb-5, .my-5 {
  margin-bottom: 45px !important;
}

.ml-5, .mx-5 {
  margin-left: 45px !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0, .py-0 {
  padding-top: 0 !important;
}

.pr-0, .px-0 {
  padding-right: 0 !important;
}

.pb-0, .py-0 {
  padding-bottom: 0 !important;
}

.pl-0, .px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 3.75px !important;
}

.pt-1, .py-1 {
  padding-top: 3.75px !important;
}

.pr-1, .px-1 {
  padding-right: 3.75px !important;
}

.pb-1, .py-1 {
  padding-bottom: 3.75px !important;
}

.pl-1, .px-1 {
  padding-left: 3.75px !important;
}

.p-2 {
  padding: 7.5px !important;
}

.pt-2, .py-2 {
  padding-top: 7.5px !important;
}

.pr-2, .px-2 {
  padding-right: 7.5px !important;
}

.pb-2, .py-2 {
  padding-bottom: 7.5px !important;
}

.pl-2, .px-2 {
  padding-left: 7.5px !important;
}

.p-3 {
  padding: 15px !important;
}

.pt-3, .py-3 {
  padding-top: 15px !important;
}

.pr-3, .px-3 {
  padding-right: 15px !important;
}

.pb-3, .py-3 {
  padding-bottom: 15px !important;
}

.pl-3, .px-3 {
  padding-left: 15px !important;
}

.p-4 {
  padding: 22.5px !important;
}

.pt-4, .py-4 {
  padding-top: 22.5px !important;
}

.pr-4, .px-4 {
  padding-right: 22.5px !important;
}

.pb-4, .py-4 {
  padding-bottom: 22.5px !important;
}

.pl-4, .px-4 {
  padding-left: 22.5px !important;
}

.p-5 {
  padding: 45px !important;
}

.pt-5, .py-5 {
  padding-top: 45px !important;
}

.pr-5, .px-5 {
  padding-right: 45px !important;
}

.pb-5, .py-5 {
  padding-bottom: 45px !important;
}

.pl-5, .px-5 {
  padding-left: 45px !important;
}

.m-n1 {
  margin: -3.75px !important;
}

.mt-n1, .my-n1 {
  margin-top: -3.75px !important;
}

.mr-n1, .mx-n1 {
  margin-right: -3.75px !important;
}

.mb-n1, .my-n1 {
  margin-bottom: -3.75px !important;
}

.ml-n1, .mx-n1 {
  margin-left: -3.75px !important;
}

.m-n2 {
  margin: -7.5px !important;
}

.mt-n2, .my-n2 {
  margin-top: -7.5px !important;
}

.mr-n2, .mx-n2 {
  margin-right: -7.5px !important;
}

.mb-n2, .my-n2 {
  margin-bottom: -7.5px !important;
}

.ml-n2, .mx-n2 {
  margin-left: -7.5px !important;
}

.m-n3 {
  margin: -15px !important;
}

.mt-n3, .my-n3 {
  margin-top: -15px !important;
}

.mr-n3, .mx-n3 {
  margin-right: -15px !important;
}

.mb-n3, .my-n3 {
  margin-bottom: -15px !important;
}

.ml-n3, .mx-n3 {
  margin-left: -15px !important;
}

.m-n4 {
  margin: -22.5px !important;
}

.mt-n4, .my-n4 {
  margin-top: -22.5px !important;
}

.mr-n4, .mx-n4 {
  margin-right: -22.5px !important;
}

.mb-n4, .my-n4 {
  margin-bottom: -22.5px !important;
}

.ml-n4, .mx-n4 {
  margin-left: -22.5px !important;
}

.m-n5 {
  margin: -45px !important;
}

.mt-n5, .my-n5 {
  margin-top: -45px !important;
}

.mr-n5, .mx-n5 {
  margin-right: -45px !important;
}

.mb-n5, .my-n5 {
  margin-bottom: -45px !important;
}

.ml-n5, .mx-n5 {
  margin-left: -45px !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto, .my-auto {
  margin-top: auto !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}

.mb-auto, .my-auto {
  margin-bottom: auto !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0, .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0, .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 3.75px !important;
  }

  .mt-sm-1, .my-sm-1 {
    margin-top: 3.75px !important;
  }

  .mr-sm-1, .mx-sm-1 {
    margin-right: 3.75px !important;
  }

  .mb-sm-1, .my-sm-1 {
    margin-bottom: 3.75px !important;
  }

  .ml-sm-1, .mx-sm-1 {
    margin-left: 3.75px !important;
  }

  .m-sm-2 {
    margin: 7.5px !important;
  }

  .mt-sm-2, .my-sm-2 {
    margin-top: 7.5px !important;
  }

  .mr-sm-2, .mx-sm-2 {
    margin-right: 7.5px !important;
  }

  .mb-sm-2, .my-sm-2 {
    margin-bottom: 7.5px !important;
  }

  .ml-sm-2, .mx-sm-2 {
    margin-left: 7.5px !important;
  }

  .m-sm-3 {
    margin: 15px !important;
  }

  .mt-sm-3, .my-sm-3 {
    margin-top: 15px !important;
  }

  .mr-sm-3, .mx-sm-3 {
    margin-right: 15px !important;
  }

  .mb-sm-3, .my-sm-3 {
    margin-bottom: 15px !important;
  }

  .ml-sm-3, .mx-sm-3 {
    margin-left: 15px !important;
  }

  .m-sm-4 {
    margin: 22.5px !important;
  }

  .mt-sm-4, .my-sm-4 {
    margin-top: 22.5px !important;
  }

  .mr-sm-4, .mx-sm-4 {
    margin-right: 22.5px !important;
  }

  .mb-sm-4, .my-sm-4 {
    margin-bottom: 22.5px !important;
  }

  .ml-sm-4, .mx-sm-4 {
    margin-left: 22.5px !important;
  }

  .m-sm-5 {
    margin: 45px !important;
  }

  .mt-sm-5, .my-sm-5 {
    margin-top: 45px !important;
  }

  .mr-sm-5, .mx-sm-5 {
    margin-right: 45px !important;
  }

  .mb-sm-5, .my-sm-5 {
    margin-bottom: 45px !important;
  }

  .ml-sm-5, .mx-sm-5 {
    margin-left: 45px !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0, .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0, .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0, .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0, .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 3.75px !important;
  }

  .pt-sm-1, .py-sm-1 {
    padding-top: 3.75px !important;
  }

  .pr-sm-1, .px-sm-1 {
    padding-right: 3.75px !important;
  }

  .pb-sm-1, .py-sm-1 {
    padding-bottom: 3.75px !important;
  }

  .pl-sm-1, .px-sm-1 {
    padding-left: 3.75px !important;
  }

  .p-sm-2 {
    padding: 7.5px !important;
  }

  .pt-sm-2, .py-sm-2 {
    padding-top: 7.5px !important;
  }

  .pr-sm-2, .px-sm-2 {
    padding-right: 7.5px !important;
  }

  .pb-sm-2, .py-sm-2 {
    padding-bottom: 7.5px !important;
  }

  .pl-sm-2, .px-sm-2 {
    padding-left: 7.5px !important;
  }

  .p-sm-3 {
    padding: 15px !important;
  }

  .pt-sm-3, .py-sm-3 {
    padding-top: 15px !important;
  }

  .pr-sm-3, .px-sm-3 {
    padding-right: 15px !important;
  }

  .pb-sm-3, .py-sm-3 {
    padding-bottom: 15px !important;
  }

  .pl-sm-3, .px-sm-3 {
    padding-left: 15px !important;
  }

  .p-sm-4 {
    padding: 22.5px !important;
  }

  .pt-sm-4, .py-sm-4 {
    padding-top: 22.5px !important;
  }

  .pr-sm-4, .px-sm-4 {
    padding-right: 22.5px !important;
  }

  .pb-sm-4, .py-sm-4 {
    padding-bottom: 22.5px !important;
  }

  .pl-sm-4, .px-sm-4 {
    padding-left: 22.5px !important;
  }

  .p-sm-5 {
    padding: 45px !important;
  }

  .pt-sm-5, .py-sm-5 {
    padding-top: 45px !important;
  }

  .pr-sm-5, .px-sm-5 {
    padding-right: 45px !important;
  }

  .pb-sm-5, .py-sm-5 {
    padding-bottom: 45px !important;
  }

  .pl-sm-5, .px-sm-5 {
    padding-left: 45px !important;
  }

  .m-sm-n1 {
    margin: -3.75px !important;
  }

  .mt-sm-n1, .my-sm-n1 {
    margin-top: -3.75px !important;
  }

  .mr-sm-n1, .mx-sm-n1 {
    margin-right: -3.75px !important;
  }

  .mb-sm-n1, .my-sm-n1 {
    margin-bottom: -3.75px !important;
  }

  .ml-sm-n1, .mx-sm-n1 {
    margin-left: -3.75px !important;
  }

  .m-sm-n2 {
    margin: -7.5px !important;
  }

  .mt-sm-n2, .my-sm-n2 {
    margin-top: -7.5px !important;
  }

  .mr-sm-n2, .mx-sm-n2 {
    margin-right: -7.5px !important;
  }

  .mb-sm-n2, .my-sm-n2 {
    margin-bottom: -7.5px !important;
  }

  .ml-sm-n2, .mx-sm-n2 {
    margin-left: -7.5px !important;
  }

  .m-sm-n3 {
    margin: -15px !important;
  }

  .mt-sm-n3, .my-sm-n3 {
    margin-top: -15px !important;
  }

  .mr-sm-n3, .mx-sm-n3 {
    margin-right: -15px !important;
  }

  .mb-sm-n3, .my-sm-n3 {
    margin-bottom: -15px !important;
  }

  .ml-sm-n3, .mx-sm-n3 {
    margin-left: -15px !important;
  }

  .m-sm-n4 {
    margin: -22.5px !important;
  }

  .mt-sm-n4, .my-sm-n4 {
    margin-top: -22.5px !important;
  }

  .mr-sm-n4, .mx-sm-n4 {
    margin-right: -22.5px !important;
  }

  .mb-sm-n4, .my-sm-n4 {
    margin-bottom: -22.5px !important;
  }

  .ml-sm-n4, .mx-sm-n4 {
    margin-left: -22.5px !important;
  }

  .m-sm-n5 {
    margin: -45px !important;
  }

  .mt-sm-n5, .my-sm-n5 {
    margin-top: -45px !important;
  }

  .mr-sm-n5, .mx-sm-n5 {
    margin-right: -45px !important;
  }

  .mb-sm-n5, .my-sm-n5 {
    margin-bottom: -45px !important;
  }

  .ml-sm-n5, .mx-sm-n5 {
    margin-left: -45px !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto, .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto, .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto, .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto, .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0, .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0, .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0, .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0, .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 3.75px !important;
  }

  .mt-md-1, .my-md-1 {
    margin-top: 3.75px !important;
  }

  .mr-md-1, .mx-md-1 {
    margin-right: 3.75px !important;
  }

  .mb-md-1, .my-md-1 {
    margin-bottom: 3.75px !important;
  }

  .ml-md-1, .mx-md-1 {
    margin-left: 3.75px !important;
  }

  .m-md-2 {
    margin: 7.5px !important;
  }

  .mt-md-2, .my-md-2 {
    margin-top: 7.5px !important;
  }

  .mr-md-2, .mx-md-2 {
    margin-right: 7.5px !important;
  }

  .mb-md-2, .my-md-2 {
    margin-bottom: 7.5px !important;
  }

  .ml-md-2, .mx-md-2 {
    margin-left: 7.5px !important;
  }

  .m-md-3 {
    margin: 15px !important;
  }

  .mt-md-3, .my-md-3 {
    margin-top: 15px !important;
  }

  .mr-md-3, .mx-md-3 {
    margin-right: 15px !important;
  }

  .mb-md-3, .my-md-3 {
    margin-bottom: 15px !important;
  }

  .ml-md-3, .mx-md-3 {
    margin-left: 15px !important;
  }

  .m-md-4 {
    margin: 22.5px !important;
  }

  .mt-md-4, .my-md-4 {
    margin-top: 22.5px !important;
  }

  .mr-md-4, .mx-md-4 {
    margin-right: 22.5px !important;
  }

  .mb-md-4, .my-md-4 {
    margin-bottom: 22.5px !important;
  }

  .ml-md-4, .mx-md-4 {
    margin-left: 22.5px !important;
  }

  .m-md-5 {
    margin: 45px !important;
  }

  .mt-md-5, .my-md-5 {
    margin-top: 45px !important;
  }

  .mr-md-5, .mx-md-5 {
    margin-right: 45px !important;
  }

  .mb-md-5, .my-md-5 {
    margin-bottom: 45px !important;
  }

  .ml-md-5, .mx-md-5 {
    margin-left: 45px !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0, .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0, .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0, .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0, .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 3.75px !important;
  }

  .pt-md-1, .py-md-1 {
    padding-top: 3.75px !important;
  }

  .pr-md-1, .px-md-1 {
    padding-right: 3.75px !important;
  }

  .pb-md-1, .py-md-1 {
    padding-bottom: 3.75px !important;
  }

  .pl-md-1, .px-md-1 {
    padding-left: 3.75px !important;
  }

  .p-md-2 {
    padding: 7.5px !important;
  }

  .pt-md-2, .py-md-2 {
    padding-top: 7.5px !important;
  }

  .pr-md-2, .px-md-2 {
    padding-right: 7.5px !important;
  }

  .pb-md-2, .py-md-2 {
    padding-bottom: 7.5px !important;
  }

  .pl-md-2, .px-md-2 {
    padding-left: 7.5px !important;
  }

  .p-md-3 {
    padding: 15px !important;
  }

  .pt-md-3, .py-md-3 {
    padding-top: 15px !important;
  }

  .pr-md-3, .px-md-3 {
    padding-right: 15px !important;
  }

  .pb-md-3, .py-md-3 {
    padding-bottom: 15px !important;
  }

  .pl-md-3, .px-md-3 {
    padding-left: 15px !important;
  }

  .p-md-4 {
    padding: 22.5px !important;
  }

  .pt-md-4, .py-md-4 {
    padding-top: 22.5px !important;
  }

  .pr-md-4, .px-md-4 {
    padding-right: 22.5px !important;
  }

  .pb-md-4, .py-md-4 {
    padding-bottom: 22.5px !important;
  }

  .pl-md-4, .px-md-4 {
    padding-left: 22.5px !important;
  }

  .p-md-5 {
    padding: 45px !important;
  }

  .pt-md-5, .py-md-5 {
    padding-top: 45px !important;
  }

  .pr-md-5, .px-md-5 {
    padding-right: 45px !important;
  }

  .pb-md-5, .py-md-5 {
    padding-bottom: 45px !important;
  }

  .pl-md-5, .px-md-5 {
    padding-left: 45px !important;
  }

  .m-md-n1 {
    margin: -3.75px !important;
  }

  .mt-md-n1, .my-md-n1 {
    margin-top: -3.75px !important;
  }

  .mr-md-n1, .mx-md-n1 {
    margin-right: -3.75px !important;
  }

  .mb-md-n1, .my-md-n1 {
    margin-bottom: -3.75px !important;
  }

  .ml-md-n1, .mx-md-n1 {
    margin-left: -3.75px !important;
  }

  .m-md-n2 {
    margin: -7.5px !important;
  }

  .mt-md-n2, .my-md-n2 {
    margin-top: -7.5px !important;
  }

  .mr-md-n2, .mx-md-n2 {
    margin-right: -7.5px !important;
  }

  .mb-md-n2, .my-md-n2 {
    margin-bottom: -7.5px !important;
  }

  .ml-md-n2, .mx-md-n2 {
    margin-left: -7.5px !important;
  }

  .m-md-n3 {
    margin: -15px !important;
  }

  .mt-md-n3, .my-md-n3 {
    margin-top: -15px !important;
  }

  .mr-md-n3, .mx-md-n3 {
    margin-right: -15px !important;
  }

  .mb-md-n3, .my-md-n3 {
    margin-bottom: -15px !important;
  }

  .ml-md-n3, .mx-md-n3 {
    margin-left: -15px !important;
  }

  .m-md-n4 {
    margin: -22.5px !important;
  }

  .mt-md-n4, .my-md-n4 {
    margin-top: -22.5px !important;
  }

  .mr-md-n4, .mx-md-n4 {
    margin-right: -22.5px !important;
  }

  .mb-md-n4, .my-md-n4 {
    margin-bottom: -22.5px !important;
  }

  .ml-md-n4, .mx-md-n4 {
    margin-left: -22.5px !important;
  }

  .m-md-n5 {
    margin: -45px !important;
  }

  .mt-md-n5, .my-md-n5 {
    margin-top: -45px !important;
  }

  .mr-md-n5, .mx-md-n5 {
    margin-right: -45px !important;
  }

  .mb-md-n5, .my-md-n5 {
    margin-bottom: -45px !important;
  }

  .ml-md-n5, .mx-md-n5 {
    margin-left: -45px !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto, .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto, .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto, .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto, .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0, .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0, .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0, .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 3.75px !important;
  }

  .mt-lg-1, .my-lg-1 {
    margin-top: 3.75px !important;
  }

  .mr-lg-1, .mx-lg-1 {
    margin-right: 3.75px !important;
  }

  .mb-lg-1, .my-lg-1 {
    margin-bottom: 3.75px !important;
  }

  .ml-lg-1, .mx-lg-1 {
    margin-left: 3.75px !important;
  }

  .m-lg-2 {
    margin: 7.5px !important;
  }

  .mt-lg-2, .my-lg-2 {
    margin-top: 7.5px !important;
  }

  .mr-lg-2, .mx-lg-2 {
    margin-right: 7.5px !important;
  }

  .mb-lg-2, .my-lg-2 {
    margin-bottom: 7.5px !important;
  }

  .ml-lg-2, .mx-lg-2 {
    margin-left: 7.5px !important;
  }

  .m-lg-3 {
    margin: 15px !important;
  }

  .mt-lg-3, .my-lg-3 {
    margin-top: 15px !important;
  }

  .mr-lg-3, .mx-lg-3 {
    margin-right: 15px !important;
  }

  .mb-lg-3, .my-lg-3 {
    margin-bottom: 15px !important;
  }

  .ml-lg-3, .mx-lg-3 {
    margin-left: 15px !important;
  }

  .m-lg-4 {
    margin: 22.5px !important;
  }

  .mt-lg-4, .my-lg-4 {
    margin-top: 22.5px !important;
  }

  .mr-lg-4, .mx-lg-4 {
    margin-right: 22.5px !important;
  }

  .mb-lg-4, .my-lg-4 {
    margin-bottom: 22.5px !important;
  }

  .ml-lg-4, .mx-lg-4 {
    margin-left: 22.5px !important;
  }

  .m-lg-5 {
    margin: 45px !important;
  }

  .mt-lg-5, .my-lg-5 {
    margin-top: 45px !important;
  }

  .mr-lg-5, .mx-lg-5 {
    margin-right: 45px !important;
  }

  .mb-lg-5, .my-lg-5 {
    margin-bottom: 45px !important;
  }

  .ml-lg-5, .mx-lg-5 {
    margin-left: 45px !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0, .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0, .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0, .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0, .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 3.75px !important;
  }

  .pt-lg-1, .py-lg-1 {
    padding-top: 3.75px !important;
  }

  .pr-lg-1, .px-lg-1 {
    padding-right: 3.75px !important;
  }

  .pb-lg-1, .py-lg-1 {
    padding-bottom: 3.75px !important;
  }

  .pl-lg-1, .px-lg-1 {
    padding-left: 3.75px !important;
  }

  .p-lg-2 {
    padding: 7.5px !important;
  }

  .pt-lg-2, .py-lg-2 {
    padding-top: 7.5px !important;
  }

  .pr-lg-2, .px-lg-2 {
    padding-right: 7.5px !important;
  }

  .pb-lg-2, .py-lg-2 {
    padding-bottom: 7.5px !important;
  }

  .pl-lg-2, .px-lg-2 {
    padding-left: 7.5px !important;
  }

  .p-lg-3 {
    padding: 15px !important;
  }

  .pt-lg-3, .py-lg-3 {
    padding-top: 15px !important;
  }

  .pr-lg-3, .px-lg-3 {
    padding-right: 15px !important;
  }

  .pb-lg-3, .py-lg-3 {
    padding-bottom: 15px !important;
  }

  .pl-lg-3, .px-lg-3 {
    padding-left: 15px !important;
  }

  .p-lg-4 {
    padding: 22.5px !important;
  }

  .pt-lg-4, .py-lg-4 {
    padding-top: 22.5px !important;
  }

  .pr-lg-4, .px-lg-4 {
    padding-right: 22.5px !important;
  }

  .pb-lg-4, .py-lg-4 {
    padding-bottom: 22.5px !important;
  }

  .pl-lg-4, .px-lg-4 {
    padding-left: 22.5px !important;
  }

  .p-lg-5 {
    padding: 45px !important;
  }

  .pt-lg-5, .py-lg-5 {
    padding-top: 45px !important;
  }

  .pr-lg-5, .px-lg-5 {
    padding-right: 45px !important;
  }

  .pb-lg-5, .py-lg-5 {
    padding-bottom: 45px !important;
  }

  .pl-lg-5, .px-lg-5 {
    padding-left: 45px !important;
  }

  .m-lg-n1 {
    margin: -3.75px !important;
  }

  .mt-lg-n1, .my-lg-n1 {
    margin-top: -3.75px !important;
  }

  .mr-lg-n1, .mx-lg-n1 {
    margin-right: -3.75px !important;
  }

  .mb-lg-n1, .my-lg-n1 {
    margin-bottom: -3.75px !important;
  }

  .ml-lg-n1, .mx-lg-n1 {
    margin-left: -3.75px !important;
  }

  .m-lg-n2 {
    margin: -7.5px !important;
  }

  .mt-lg-n2, .my-lg-n2 {
    margin-top: -7.5px !important;
  }

  .mr-lg-n2, .mx-lg-n2 {
    margin-right: -7.5px !important;
  }

  .mb-lg-n2, .my-lg-n2 {
    margin-bottom: -7.5px !important;
  }

  .ml-lg-n2, .mx-lg-n2 {
    margin-left: -7.5px !important;
  }

  .m-lg-n3 {
    margin: -15px !important;
  }

  .mt-lg-n3, .my-lg-n3 {
    margin-top: -15px !important;
  }

  .mr-lg-n3, .mx-lg-n3 {
    margin-right: -15px !important;
  }

  .mb-lg-n3, .my-lg-n3 {
    margin-bottom: -15px !important;
  }

  .ml-lg-n3, .mx-lg-n3 {
    margin-left: -15px !important;
  }

  .m-lg-n4 {
    margin: -22.5px !important;
  }

  .mt-lg-n4, .my-lg-n4 {
    margin-top: -22.5px !important;
  }

  .mr-lg-n4, .mx-lg-n4 {
    margin-right: -22.5px !important;
  }

  .mb-lg-n4, .my-lg-n4 {
    margin-bottom: -22.5px !important;
  }

  .ml-lg-n4, .mx-lg-n4 {
    margin-left: -22.5px !important;
  }

  .m-lg-n5 {
    margin: -45px !important;
  }

  .mt-lg-n5, .my-lg-n5 {
    margin-top: -45px !important;
  }

  .mr-lg-n5, .mx-lg-n5 {
    margin-right: -45px !important;
  }

  .mb-lg-n5, .my-lg-n5 {
    margin-bottom: -45px !important;
  }

  .ml-lg-n5, .mx-lg-n5 {
    margin-left: -45px !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto, .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto, .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto, .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto, .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0, .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0, .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0, .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0, .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 3.75px !important;
  }

  .mt-xl-1, .my-xl-1 {
    margin-top: 3.75px !important;
  }

  .mr-xl-1, .mx-xl-1 {
    margin-right: 3.75px !important;
  }

  .mb-xl-1, .my-xl-1 {
    margin-bottom: 3.75px !important;
  }

  .ml-xl-1, .mx-xl-1 {
    margin-left: 3.75px !important;
  }

  .m-xl-2 {
    margin: 7.5px !important;
  }

  .mt-xl-2, .my-xl-2 {
    margin-top: 7.5px !important;
  }

  .mr-xl-2, .mx-xl-2 {
    margin-right: 7.5px !important;
  }

  .mb-xl-2, .my-xl-2 {
    margin-bottom: 7.5px !important;
  }

  .ml-xl-2, .mx-xl-2 {
    margin-left: 7.5px !important;
  }

  .m-xl-3 {
    margin: 15px !important;
  }

  .mt-xl-3, .my-xl-3 {
    margin-top: 15px !important;
  }

  .mr-xl-3, .mx-xl-3 {
    margin-right: 15px !important;
  }

  .mb-xl-3, .my-xl-3 {
    margin-bottom: 15px !important;
  }

  .ml-xl-3, .mx-xl-3 {
    margin-left: 15px !important;
  }

  .m-xl-4 {
    margin: 22.5px !important;
  }

  .mt-xl-4, .my-xl-4 {
    margin-top: 22.5px !important;
  }

  .mr-xl-4, .mx-xl-4 {
    margin-right: 22.5px !important;
  }

  .mb-xl-4, .my-xl-4 {
    margin-bottom: 22.5px !important;
  }

  .ml-xl-4, .mx-xl-4 {
    margin-left: 22.5px !important;
  }

  .m-xl-5 {
    margin: 45px !important;
  }

  .mt-xl-5, .my-xl-5 {
    margin-top: 45px !important;
  }

  .mr-xl-5, .mx-xl-5 {
    margin-right: 45px !important;
  }

  .mb-xl-5, .my-xl-5 {
    margin-bottom: 45px !important;
  }

  .ml-xl-5, .mx-xl-5 {
    margin-left: 45px !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0, .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0, .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0, .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0, .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 3.75px !important;
  }

  .pt-xl-1, .py-xl-1 {
    padding-top: 3.75px !important;
  }

  .pr-xl-1, .px-xl-1 {
    padding-right: 3.75px !important;
  }

  .pb-xl-1, .py-xl-1 {
    padding-bottom: 3.75px !important;
  }

  .pl-xl-1, .px-xl-1 {
    padding-left: 3.75px !important;
  }

  .p-xl-2 {
    padding: 7.5px !important;
  }

  .pt-xl-2, .py-xl-2 {
    padding-top: 7.5px !important;
  }

  .pr-xl-2, .px-xl-2 {
    padding-right: 7.5px !important;
  }

  .pb-xl-2, .py-xl-2 {
    padding-bottom: 7.5px !important;
  }

  .pl-xl-2, .px-xl-2 {
    padding-left: 7.5px !important;
  }

  .p-xl-3 {
    padding: 15px !important;
  }

  .pt-xl-3, .py-xl-3 {
    padding-top: 15px !important;
  }

  .pr-xl-3, .px-xl-3 {
    padding-right: 15px !important;
  }

  .pb-xl-3, .py-xl-3 {
    padding-bottom: 15px !important;
  }

  .pl-xl-3, .px-xl-3 {
    padding-left: 15px !important;
  }

  .p-xl-4 {
    padding: 22.5px !important;
  }

  .pt-xl-4, .py-xl-4 {
    padding-top: 22.5px !important;
  }

  .pr-xl-4, .px-xl-4 {
    padding-right: 22.5px !important;
  }

  .pb-xl-4, .py-xl-4 {
    padding-bottom: 22.5px !important;
  }

  .pl-xl-4, .px-xl-4 {
    padding-left: 22.5px !important;
  }

  .p-xl-5 {
    padding: 45px !important;
  }

  .pt-xl-5, .py-xl-5 {
    padding-top: 45px !important;
  }

  .pr-xl-5, .px-xl-5 {
    padding-right: 45px !important;
  }

  .pb-xl-5, .py-xl-5 {
    padding-bottom: 45px !important;
  }

  .pl-xl-5, .px-xl-5 {
    padding-left: 45px !important;
  }

  .m-xl-n1 {
    margin: -3.75px !important;
  }

  .mt-xl-n1, .my-xl-n1 {
    margin-top: -3.75px !important;
  }

  .mr-xl-n1, .mx-xl-n1 {
    margin-right: -3.75px !important;
  }

  .mb-xl-n1, .my-xl-n1 {
    margin-bottom: -3.75px !important;
  }

  .ml-xl-n1, .mx-xl-n1 {
    margin-left: -3.75px !important;
  }

  .m-xl-n2 {
    margin: -7.5px !important;
  }

  .mt-xl-n2, .my-xl-n2 {
    margin-top: -7.5px !important;
  }

  .mr-xl-n2, .mx-xl-n2 {
    margin-right: -7.5px !important;
  }

  .mb-xl-n2, .my-xl-n2 {
    margin-bottom: -7.5px !important;
  }

  .ml-xl-n2, .mx-xl-n2 {
    margin-left: -7.5px !important;
  }

  .m-xl-n3 {
    margin: -15px !important;
  }

  .mt-xl-n3, .my-xl-n3 {
    margin-top: -15px !important;
  }

  .mr-xl-n3, .mx-xl-n3 {
    margin-right: -15px !important;
  }

  .mb-xl-n3, .my-xl-n3 {
    margin-bottom: -15px !important;
  }

  .ml-xl-n3, .mx-xl-n3 {
    margin-left: -15px !important;
  }

  .m-xl-n4 {
    margin: -22.5px !important;
  }

  .mt-xl-n4, .my-xl-n4 {
    margin-top: -22.5px !important;
  }

  .mr-xl-n4, .mx-xl-n4 {
    margin-right: -22.5px !important;
  }

  .mb-xl-n4, .my-xl-n4 {
    margin-bottom: -22.5px !important;
  }

  .ml-xl-n4, .mx-xl-n4 {
    margin-left: -22.5px !important;
  }

  .m-xl-n5 {
    margin: -45px !important;
  }

  .mt-xl-n5, .my-xl-n5 {
    margin-top: -45px !important;
  }

  .mr-xl-n5, .mx-xl-n5 {
    margin-right: -45px !important;
  }

  .mb-xl-n5, .my-xl-n5 {
    margin-bottom: -45px !important;
  }

  .ml-xl-n5, .mx-xl-n5 {
    margin-left: -45px !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto, .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto, .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto, .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto, .mx-xl-auto {
    margin-left: auto !important;
  }
}

.alert {
  border: 1px solid #0000;
  border-radius: .25rem;
  margin-bottom: 1rem;
  padding: .75rem 1.25rem;
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  z-index: 2;
  color: inherit;
  padding: .75rem 1.25rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

body {
  font-family: Helvetica Neue, Helvetica, Arial;
}

.bg-grey {
  background: #86868641;
}

.is-sticky {
  z-index: 10 !important;
}

img {
  max-width: 100%;
  height: auto !important;
}

.actions > [class^=" action"], .actions > [class^="action"], .actions .action-edit, .actions .action-delete {
  font-size: .9375rem;
  line-height: auto;
  color: #fff;
  background-color: #707070;
  border-color: #000;
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 5px 15px;
  display: inline-block;
}

.actions > [class^=" action"]:hover, .actions > [class^="action"]:hover, .actions .action-edit:hover, .actions .action-delete:hover {
  color: #fff;
  background-color: #346fe9;
  border-color: #000;
}

.actions > [class^=" action"]:focus, .actions > [class^=" action"].focus, .actions > [class^="action"]:focus, .actions > [class^="action"].focus, .actions .action-edit:focus, .actions .action-edit.focus, .actions .action-delete:focus, .actions .action-delete.focus {
  color: #fff;
  background-color: #346fe9;
  border-color: #000;
  box-shadow: 0 0 0 .2rem #26262680;
}

.actions > [class^=" action"].disabled, .actions > [class^=" action"]:disabled, .actions > [class^="action"].disabled, .actions > [class^="action"]:disabled, .actions .action-edit.disabled, .actions .action-edit:disabled, .actions .action-delete.disabled, .actions .action-delete:disabled {
  color: #fff;
  background-color: #707070;
  border-color: #000;
}

.actions > [class^=" action"]:not(:disabled):not(.disabled):active, .actions > [class^=" action"]:not(:disabled):not(.disabled).active, .show > .actions > [class^=" action"].dropdown-toggle, .actions > [class^="action"]:not(:disabled):not(.disabled):active, .actions > [class^="action"]:not(:disabled):not(.disabled).active, .show > .actions > [class^="action"].dropdown-toggle, .actions .action-edit:not(:disabled):not(.disabled):active, .actions .action-edit:not(:disabled):not(.disabled).active, .show > .actions .action-edit.dropdown-toggle, .actions .action-delete:not(:disabled):not(.disabled):active, .actions .action-delete:not(:disabled):not(.disabled).active, .show > .actions .action-delete.dropdown-toggle {
  color: #fff;
  background-color: #575757;
  border-color: #000;
}

.actions > [class^=" action"]:not(:disabled):not(.disabled):active:focus, .actions > [class^=" action"]:not(:disabled):not(.disabled).active:focus, .show > .actions > [class^=" action"].dropdown-toggle:focus, .actions > [class^="action"]:not(:disabled):not(.disabled):active:focus, .actions > [class^="action"]:not(:disabled):not(.disabled).active:focus, .show > .actions > [class^="action"].dropdown-toggle:focus, .actions .action-edit:not(:disabled):not(.disabled):active:focus, .actions .action-edit:not(:disabled):not(.disabled).active:focus, .show > .actions .action-edit.dropdown-toggle:focus, .actions .action-delete:not(:disabled):not(.disabled):active:focus, .actions .action-delete:not(:disabled):not(.disabled).active:focus, .show > .actions .action-delete.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #26262680;
}

.actions .action-edit {
  white-space: nowrap;
  color: #fff;
  background-color: #081d49;
  border-color: #000;
  color: #fff !important;
}

.actions .action-edit:hover {
  color: #fff;
  background-color: #346fe9;
  border-color: #000;
}

.actions .action-edit:focus, .actions .action-edit.focus {
  color: #fff;
  background-color: #346fe9;
  border-color: #000;
  box-shadow: 0 0 0 .2rem #26262680;
}

.actions .action-edit.disabled, .actions .action-edit:disabled {
  color: #fff;
  background-color: #081d49;
  border-color: #000;
}

.actions .action-edit:not(:disabled):not(.disabled):active, .actions .action-edit:not(:disabled):not(.disabled).active, .show > .actions .action-edit.dropdown-toggle {
  color: #fff;
  background-color: #030b1b;
  border-color: #000;
}

.actions .action-edit:not(:disabled):not(.disabled):active:focus, .actions .action-edit:not(:disabled):not(.disabled).active:focus, .show > .actions .action-edit.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #26262680;
}

.actions .action-edit:before {
  content: "View / ";
}

.actions .action-delete {
  color: #fff;
  background-color: #c2000b;
  border-color: #000;
  color: #fff !important;
}

.actions .action-delete:hover {
  color: #212529;
  background-color: #ff8f95;
  border-color: #000;
}

.actions .action-delete:focus, .actions .action-delete.focus {
  color: #212529;
  background-color: #ff8f95;
  border-color: #000;
  box-shadow: 0 0 0 .2rem #26262680;
}

.actions .action-delete.disabled, .actions .action-delete:disabled {
  color: #fff;
  background-color: #c2000b;
  border-color: #000;
}

.actions .action-delete:not(:disabled):not(.disabled):active, .actions .action-delete:not(:disabled):not(.disabled).active, .show > .actions .action-delete.dropdown-toggle {
  color: #fff;
  background-color: #8f0008;
  border-color: #000;
}

.actions .action-delete:not(:disabled):not(.disabled):active:focus, .actions .action-delete:not(:disabled):not(.disabled).active:focus, .show > .actions .action-delete.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #26262680;
}

.actions .action-export-event-names, .page-actions .action-export-event-names {
  color: #fff;
  background-color: green;
}

.is-sticky .content-header {
  background: #fff;
  border-bottom: 1px solid #86868641;
  z-index: 10 !important;
  margin: 0 !important;
}

.is-sticky .content-header:after, .is-sticky .content-header:before {
  content: " ";
  width: 30px;
  height: 100%;
  background: #fff;
  border-bottom: 1px solid #86868641;
  display: block;
  position: absolute;
  bottom: -1px;
}

.is-sticky .content-header:after {
  right: 100%;
}

.is-sticky .content-header:before {
  left: 100%;
}

.nav-tabs .nav-item.enabled a:after {
  content: " ☑";
}

.nav-tabs .nav-item.error a {
  color: #c2000b;
}

.nav-tabs .nav-item.error a:after {
  width: 15px;
  height: 10px;
  content: "";
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23cd3c63'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23cd3c63' stroke='none'/%3E%3C/svg%3E");
  background-position: 100%;
  background-repeat: no-repeat;
  display: inline-block;
}

.login-logo {
  width: 350px;
}

.login {
  color: #707070;
}

.login a {
  color: #081d49;
  text-decoration: none;
}

.login a:hover {
  text-decoration: underline;
}

.login .logged-in {
  color: #c2000b;
  padding-top: 30px;
}

.login h1 {
  color: #707070;
  margin-bottom: 40px;
  padding-top: 10px;
  font-size: 40px;
  line-height: 49px;
}

.login label {
  text-transform: uppercase;
  padding-bottom: 5px;
  font-weight: bold;
  display: block;
}

.login input[type="email"], .login input[type="password"], .login input[type="text"] {
  width: 100%;
  border: 1px solid #707070;
  border-radius: 0;
  margin-bottom: 20px;
  padding: 5px 10px;
  line-height: 31px;
}

.login .checkbox {
  margin-bottom: 20px;
  display: block;
}

.login input[type="submit"], .login button {
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  background: #081d49;
  border: none;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 9px 27px;
  font-size: 20px;
  line-height: 20px;
}

.login .reset-password {
  margin-bottom: 20px;
}

table.history tr + tr > td {
  border-top: 1px solid #ddd;
}

table.history td {
  padding: 3px 10px;
}

table.history td + td {
  border-left: 1px solid #ddd;
}

.search-duplicate-results .inner {
  z-index: 10000;
  color: #126244;
  padding: 5px 0;
  top: 30px;
  right: 30px;
}

.search-duplicate-results .inner ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.search-duplicate-results .inner ul li {
  padding-right: 5px;
  display: inline-block;
}

.search-duplicate-results .inner ul li:after {
  content: ",";
}

.search-duplicate-results .inner ul li:last-child:after {
  content: "";
}

.form-group.remove-group {
  background: #f003;
}

.form-group.remove-group .field-collection-delete-button {
  color: green;
}

.form-group.remove-group .form-group.field-collection .accordion-button {
  color: red;
}

/*# sourceMappingURL=app.css.map */
